<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="12">
            <b-form-group
              label="اسم الاعتماد"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="رابط موقع الاعتماد"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addForm.link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                اشعار الاعتماد
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://ar.lpcentre.com/${addForm.image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group

                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Logo "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addForm.alt_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
              الصورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="`https://ar.lpcentre.com/${addForm.certificate}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group

                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Image  "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addForm.certificate"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                   
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="12">
              <b-form-group
              label="التفاصيل"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules=""
                >
             

                   <QuillEditor
                  :editorRef="'descriptionEditor'"
                  v-model="addForm.description"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Editor from '@tinymce/tinymce-vue'
import Vue from 'vue'
import store from '@/store'
import QuillEditor from "@core/components/editor/Editor";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    store.dispatch('accreditations/GetAccreditations', { id })
      .then(response => {
        store.commit('accreditations/GET_ACCREDITATION', response?.data.data)
      })

    const Form = computed(() => store.state.accreditations.accreditation)
    const addForm = reactive(Form)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )

    const addFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('image', addForm.value.image)
          formData.append('name', addForm.value.name)
          formData.append('link', addForm.value.link)
          formData.append('alt_image', addForm.value.alt_image)
          formData.append('description', addForm.value.description)
          formData.append('certificate',  addForm.value.certificate)
          formData.append('_method', 'put')

          store.dispatch('accreditations/UpdateAccreditations', { id, formData })
            .then(response => {
              Vue.swal({
                title: 'تم التحديث  ',
                confirmButtonText: 'حسنا',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    function example_image_upload_handler (blobInfo, success, failure, progress) {
  var xhr, formData;
let token=localStorage.getItem('token')

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', 'https://lpcentre.com/api/upload_image_tiny_mce');

  xhr.upload.onprogress = function (e) {
    progress(e.loaded / e.total * 100);
  };
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.onload = function() {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  formData = new FormData();
  formData.append('image', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};
    return {
      refInputEl,
      example_image_upload_handler,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,

      Tabimage,
      addForm,
      inputImageRenderer,
      addFormvalidate,
      id,
      Form,


      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    QuillEditor

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
